<template>
  <div class="settings" style=" padding-top: 60px; min-width: 1170px">
    <div class="pro-landing-header">
      <div style="margin-top: 101px;">
        <p class="header-title">
         bicoin<span style="font-weight: 300;">’leri Toplayın;<br> Öncelik, Zaman ve Görünürlük Kazanın</span>
        </p>
        <p class="header-description">
          Bionluk’ta yaptığın her satış, sana bicoin olarak geri döner. Hesabında biriken bicoin’lerini, iş ilanlarını öne çıkarmak veya sana mesaj hiç göndermemiş kullanıcılarla iletişime geçmek için kullanabilirsin.
        </p>

      </div>
    </div>

    <div class="bicoin-how-works-body">
      <div class="how-works-main">
        <p class="how-works-title">Nasıl çalışır?</p>
        <div style="display: flex; margin-top: 32px;">
          <div class="work-desc-main" v-for="work in howWorksArray">
            <img :src="work.imageUrl"/>
            <p class="work-title">{{work.title}}</p>
            <p class="work-description">{{work.description}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="faq-body">
      <p class="faq-title">Sıkça sorulan sorular</p>
      <div class="faq-main">
        <div class="faq-array-div" v-for="faq in faqs">
          <div v-if="!faq.open" class="faq-close-div" @click="reverseFaq(faq)">
            <p>{{faq.question}}</p>
            <img src="https://gcdn.bionluk.com/site/icon/tansel_f_o_altok.svg"/>
          </div>
          <div v-if="faq.open" class="faq-open-div" @click="reverseFaq(faq)">
            <div style="display: flex; justify-content: space-between;">
              <p class="faq-open-question">{{faq.question}}</p>
              <img class="faq-open-image" src="https://gcdn.bionluk.com/site/icon/tansel_f_o_ustok.svg"/>
            </div>
            <p class="faq-open-answer">{{faq.answer}}</p>
          </div>
        </div>
      </div>
      <div class="faq-bottom">

        <div><router-link style="color: white" to="/panel/bicoin">bicoin hesabıma git!</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-main-bicoin-bicoin",
    data() {
      return {
        howWorksArray: [
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_3.png',
            title: 'Çalıştıkça Birikir',
            description: 'Bionluk’taki çalışma performansınla doğru orantılı olarak bicoin kazanırsın ve her satış yaptığında bicoin’lerin hesabına yüklenir. '
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_2.png',
            title: 'Görünürlük ve Zaman Kazandırır',
            description: 'Biriken bicoin’lerini arama sayfalarında listelenen ilanlar arasında öne çıkmak veya size hiç mesaj göndermemiş kullanıcılarla iletişime geçmek için kullanırsın.\n'
          },
          {
            imageUrl: 'https://gcdn.bionluk.com/site/icon/tansel_f_o_1.png',
            title: 'İşleri Artırır',
            description: 'Görünürlüğün arttıkça daha fazla iş almaya ve bitirdiğin işler sonucunda daha fazla bicoin kazanmaya başlarsın. '
          },
        ],
        faqs: [
          {
            open: false,
            question: 'bicoin nedir?',
            answer: 'bicoin; Bionluk üyesi freelancer’ların Bionluk’ta yaptıkları satışlar sayesinde bakiye kazandıkları özel bir kullanıcı programıdır. bicoin hesabına yüklenen bicoin’ler sadece Bionluk’ta harcanabilir.'
          },
          {
            open: false,
            question: 'bicoin parayla satılır mı?',
            answer: 'bicoin parayla satılamaz, takas veya transfer edilemez. Bionluk’taki satış performansına bağlı olarak yalnızca Bionluk tarafından bicoin hesabına yüklenir. bicoin hesabına, freelancer olarak Bionluk’a giriş yaptıktan sonra ana sayfada bulunan “Freelancer” panelinden ulaşabilirsin.'
          },
          {
            open: false,
            question: 'Nasıl bicoin kazanılır?',
            answer: 'Bionluk’ta onaylanan satışlarından kazandığın her 1 TL için 1 bicoin kazanırsın. Örneğin 100 TL tutarında bir satış yaptığında, 100 bicoin otomatik olarak hesabına yüklenir.'
          },
          {
            open: false,
            question: 'Nasıl bicoin harcanır?',
            answer: 'Bicoin harcamanın en önemli avantajı, sana satışlarını artırma imkanı vermesidir. Biriktirdiğin bicoin’leri bicoin hesabındaki “bicoin’leri kullan” butonuna tıklayarak değerlendirebilirsin. bicoin’leri değerlendirmenin iki yolu vardır: 1-) Kategori sayfalarında alıcılar için listelenen ilanlar arasında, iş ilanını öne çıkararak görünürlüğünü artırmak.  \n' +
            '\n' +
            '2-) Henüz sana hiç mesaj göndermemiş kullanıcılarla iletişime geçmek'
          },
          {
            open: false,
            question: 'Öne çıkma özelliği nasıl kullanılır?',
            answer: 'Alıcılar tarafından görüntülenen iş ilanları arasından kendi iş ilanını daha görünür kılmak için “Öne Çıkma” özelliğinden faydalanabilirsin. 500 bicoin harcayarak iş ilanını listeleme sayfalarında en üst sıraya taşıyabilirsin. Bu alandaki ilanlar gün içinde değişmekle birlikte üst sıraya taşıdığın iş ilanların, 24 saat boyunca dönüşümlü olarak öne çıkanlar arasında sergilenir. '
          }
        ],
      }
    },

    methods: {
      reverseFaq(faq) {
        faq.open = !faq.open;
      }
    },

    watch: {},

    created() {

    }

  }
</script>

<style scoped lang="scss">

  .pro-landing-header {
    margin: 0 auto;
    height: 410px;
    text-align: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-title {
      font-size: 52px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }
    .header-description {
      width: 670px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.33;
      margin: auto;
      margin-top: 20px;
      color: #8b95a1;
    }

    .header-button {
      width: 193px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      margin: 0 auto;
      margin-top: 32px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }


  .bicoin-how-works-body {
    min-width: 1170px;
    padding-bottom: 57px;
    padding-top: 57px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .how-works-main {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 1170px;
    .how-works-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
    }
  }

  .work-desc-main {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    align-items: center;
    &:nth-child(3) {
      margin-right: 0;
    }

    img {
      width: 89px;
      height: 89px;
    }
    .work-title {
      margin-top: 3px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }

    .work-description {
      margin-top: 17px;
      width: 300px;
      height: 100px;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #8b95a1;
    }

  }

  .faq-body {
    min-width: 1170px;
    padding-bottom: 53px;
    padding-top: 52px;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;

    .faq-title {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: #333333;
    }
  }

  .faq-main {
    display: flex;
    flex-direction: column;
    margin-top: 43px;
    .faq-array-div {
      width: 944px;
    }
  }

  .faq-close-div {
    border-top: solid 1px #e0e0e0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 18px 23px 20px;
    cursor: pointer;

    p {
      font-size: 20px;
      text-align: left;
      color: #2d3640;
    }
  }

  .faq-open-div {
    border-top: solid 1px #e0e0e0;
    padding: 20px;
    width: 904px;
    height: 169px;
    background-color: #ffffff;
    cursor: pointer;
    .faq-open-question {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      color: #2d3640;
    }

    .faq-open-answer {
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
      color: #8b95a1;
      margin-top: 26px;
    }
  }

  .faq-bottom {
    width: 1140px;
    height: 201px;
    margin-top: 52px;
    background: url('https://gcdn.bionluk.com/site/icon/freelancer_olmak_bg_alt.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
      margin-right: 30px;
    }

    div {
      width: 200px;
      height: 50px;
      border-radius: 2px;
      background-color: #fd4056;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      cursor: pointer;
    }
  }
</style>
